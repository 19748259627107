import { makeVar } from "@apollo/client";
import { ICompany } from "../@types/graphql";
import { IReview } from "../@types/rest/reviews";

type TToolbarContent =
  | { type: "company"; data?: ICompany }
  | { type: "sku"; data?: { id: string } }
  | { type: "prices"; data?: undefined }
  | { type: "review"; data: IReview };

export const toolbarContentVar = makeVar<TToolbarContent>(undefined);

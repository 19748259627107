import "../../styles/pages/storePage.css";
import { ButtonSwitcher } from "../../components/common/buttonSwitcher";
import { CustomButton } from "../../components/common/customButton";
import { useNavigate } from "react-router-dom";
import { NetworkStatus, useQuery } from "@apollo/client";
import { GET_COMPANIES } from "../../api/graphql/companies";
import { FullContainerSpinner } from "../../components/common/fullContainerSpinner";
import { ICompany } from "../../@types/graphql";
import { toolbarContentVar } from "../../store/toolbar.store";
import { btns } from "../store/storePage";

import Plus from "../../assets/icons/plus.svg?react";

export const CompaniesPage = () => {
  const navigate = useNavigate();
  const { data, networkStatus } = useQuery<{ companies: ICompany[] }>(
    GET_COMPANIES,
  );

  const companies = data?.companies || [];

  const isLoading = [
    NetworkStatus.loading,
    NetworkStatus.setVariables,
  ].includes(networkStatus as number);

  return (
    <div className="default-page">
      <h2 className="mainTitle flex items-center gap-24 justify-between">
        Компании
        <CustomButton
          leftIcon={Plus}
          onClick={() => {
            toolbarContentVar({ type: "company" });
          }}
        >
          Добавить компанию
        </CustomButton>
      </h2>
      <div className="custom-store-page-actions">
        <ButtonSwitcher
          value="companies"
          items={btns}
          onChange={(i) => navigate(`/${i.value}`)}
        />
      </div>
      {companies.length > 0 && !isLoading && (
        <div className="custom-store-cards">
          {companies.map((i) => (
            <div className="custom-store-card" key={i.id}>
              <div className="custom-store-name">{i.name}</div>
              <div className="custom-store-seller-id">EOG ID: {i.id}</div>
            </div>
          ))}
        </div>
      )}
      {isLoading && <FullContainerSpinner />}
    </div>
  );
};
